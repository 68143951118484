@import "src/themes/mojito/styles/index.scss";
.form-item {
  width: 85%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 24px;
  gap: 4px;
  @include sm {
    width: 50%;
    flex-direction: row;
  }

  p:first-of-type {
    margin-bottom: space(0.5);

    @include sm {
      min-width: 100px;
    }
  }

  p.item-data {
    color: getvar($colors, "neutral", "500");
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include sm {
      padding-right: space(0.75);
    }
  }
}
