@import "src/themes/mojito/styles/index.scss";
.Billing__information {
  @include lg {
    width: 50%;
  }

  &__form-section {
    display: flex;
    flex-direction: column;

    @include sm {
      flex-direction: row;
      .form-item:first-of-type {
        width: 50%;
        overflow: hidden;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;

    .button {
      width: auto !important;
      .button__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
    }
    #billing-browse-plans {
      .button__text {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }

  &__chip {
    position: absolute;
    top: space(1.5);
    right: space(1.5);
    color: white;
    border-color: getvar($colors, "primary-green", "500");
    background-color: getvar($colors, "primary-green", "500");
  }
}

.ReactModal__Content.billingInformation__modal,
.ReactModal__Content.userInformation__modal {
  width: 343px !important;
  overflow: auto;
  @include sm {
    width: 543px !important;
    max-width: 543px !important;
  }
  @include lg {
    width: 650px !important;
    max-width: 650px !important;
  }
}

.ReactModal__Content.billingInformation__modal-country {
  .modal-buttons_container {
    .button {
      padding: 1rem !important;
    }
  }
}
