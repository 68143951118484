@import "src/themes/mojito/styles/index.scss";
.dashboard__card {
  position: relative;
  box-shadow: 2px 0px 6px 0px rgba(166, 166, 166, 0.25);
  &-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
