@import "src/themes/mojito/styles/index.scss";
.frame {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.2);

  svg {
    width: 100%;
    height: 100%;
  }
}
