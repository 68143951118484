@import "src/themes/mojito/styles/index.scss";
.Input {
  // @extend .u-spacing-5;

  &__label-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row !important;

    .Input__label {
      text-align: left;
      display: block;
    }

    .tooltip {
      flex: none;
      .tooltip-text {
        min-width: 230px;
      }
      .helper-icon {
        color: getvar($colors, "neutral", "500");
      }
    }
  }

  &__wrapper {
    position: relative;
  }

  &__passwordToggle {
    cursor: pointer;
    color: getvar($colors, "neutral", "500");
    display: flex;
    position: absolute;
    right: space(0.75);
    top: 50%;
    transform: translateY(-50%);

    svg {
      height: space(1.25);
      width: space(1.25);
    }
  }

  &__element {
    display: block;
    width: 100%;
    padding: space(0.75);
    border: 1px solid getvar($colors, "neutral", "300");
    border-radius: space(0.25);
    font-size: 14px;
    transition: ease-in-out 0.2s border;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-family: getvar($typography, "font-family-body");

    &:hover {
      border: 1px solid getvar($colors, "neutral", "500");
    }

    &:active {
      border: 1px solid getvar($colors, "primary", "500");
    }

    &:focus {
      outline: none;
      border: 1px solid getvar($colors, "primary", "500");
    }
  }

  &__error-message {
    display: flex;
    margin-top: space(0.25);
    margin-bottom: space(0.5);
    align-items: flex-start;

    svg {
      color: getvar($colors, "error", "600");
      margin-right: space(0.25);
      min-height: 14px;
      min-width: 14px;
      margin-top: 2px;
    }
  }

  &--error {
    .Input__error-message {
      text-align: left;
      color: getvar($colors, "error", "600");
    }
    .Input__element {
      border: 1px solid getvar($colors, "error", "500");
      padding: space(0.75) space(2) space(0.75) space(0.75);
    }
  }

  &--success {
    .Input__wrapper {
      &:after {
        content: "";
        background-image: url("/images/check-icon.png");
        height: space();
        width: space();
        position: absolute;
        top: space(0.8);
        right: space(0.75);
      }
    }

    .Input__element {
      border: 1px solid getvar($colors, "neutral", "600");
      padding: space(0.75) space(2) space(0.75) space(0.75);
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;

    .Input__element {
      background-color: getvar($colors, "neutral", "100");
      border: 1px solid getvar($colors, "neutral", "500");
    }
  }

  &__helper {
    color: getvar($colors, "neutral", "400");
    display: inline-block;
    font-size: space(0.75);
    line-height: space();
    margin-top: space(0.5);
  }

  &--colorPicker {
    .Input__wrapper {
      display: flex;
      align-items: center;

      &:after {
        right: space(3.75);
      }
    }
  }

  &--noMb {
    margin-bottom: 0;
  }
}
