@import "src/themes/mojito/styles/index.scss";
.skeleton {
  &__plan {
    width: space(17);
    height: space(1.5);
  }
}

.unsubscribe {
  &__header {
    background-color: getvar($colors, "neutral", "white");
    padding: space(1.5) space(1);

    &__text {
      @include sm {
        display: flex;
        justify-content: space-between;
      }

      &__plan {
        display: flex;
        gap: space(1);
      }
    }

    &__back-button {
      display: flex;
      align-items: center;
      margin-left: space(0.5);
      margin-bottom: space(0.5);
    }
  }
}
